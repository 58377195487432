import { Controller } from  'stimulus'

export default class extends Controller {
    static targets = [ "checkAll", "checkOne" ]

    connect() {
        if (this.hasCheckAllTarget) {
            this.updateCheckAll()
        }
    }

    updateCheckAll() {
        this.checkAllTarget.checked = this.checkOneTargets.every(input => input.checked)
    }
    toggleCheckAll(e) {
        if (e.target !== this.checkAllTarget) {
            this.checkAllTarget.checked = !this.checkAllTarget.checked
        }
        this.checkOneTargets.forEach(input => {
            input.checked = this.checkAllTarget.checked
        })
    }

    toggleCheckOne(e) {
        const checkbox = e.target.closest("tr").querySelector("input[data-shared--check-all-target='checkOne']")
        if (e.target !== checkbox){
            checkbox.checked = !checkbox.checked
        }
        this.updateCheckAll()
    }
}