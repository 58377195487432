import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['lessThan', 'greaterOrEqual', 'category']

  connect() {
    this.onCategorySelect()
  }

  clearCategory() {
    this.categoryTarget.value = ""
  }

  onCategorySelect() {
    if (!this.categoryTarget.value || this.categoryTarget.value === "") {
      return
    }
    switch (this.categoryTarget.value) {
      case 'Child':
        this.lessThanTarget.value = 18
        this.greaterOrEqualTarget.value = ''
        break;
      case 'Adult':
        this.lessThanTarget.value = 65
        this.greaterOrEqualTarget.value = 18
        break;
      case 'Older Adult':
        this.lessThanTarget.value = ''
        this.greaterOrEqualTarget.value = 65
        break;
    }
    $(this.lessThanTarget).effect("highlight", {}, 250);
    $(this.greaterOrEqualTarget).effect("highlight", {}, 250);
  }
}