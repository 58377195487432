import { Controller } from  'stimulus'

export default class extends Controller {
    static targets = ["display", "table", "selectTableWrapper", "form"]

    connect() {
        if (this.formTarget.querySelectorAll("#errorExplanation").length > 0){
            this.toggleVisibility()
        }
    }


    toggleVisibility(e = null) {
        e?.preventDefault()
        this.oddRow = true
        this.handleDisplay()
        this.handleReadOnlyTable()
    }


    handleDisplay(){
        for (const target of this.displayTargets){
            target.hidden = !target.hidden
        }
        const headerList = this.selectTableWrapperTarget.querySelectorAll("thead tr th")
        const selectAll = headerList[headerList.length - 1]
        selectAll.hidden = !selectAll.hidden
        this.selectTableWrapperTarget.querySelectorAll("table")[0].classList.toggle("table-striped")
    }

    handleReadOnlyTable() {
        this.selectTableWrapperTarget.querySelectorAll("tbody tr").forEach(( row ) => {
            const cellList = row.querySelectorAll("td")
            cellList[cellList.length - 1].hidden = !cellList[cellList.length - 1].hidden
            row.classList.toggle("clickable")
            row.classList.toggle("disable-pointer")
            const input = row.querySelectorAll("input")[0]
            if (!input || !input.checked) {
                row.hidden = !row.hidden
            } else {
                this.oddRow ? row.classList.toggle("active") : null
                this.oddRow = !this.oddRow
            }
        })
    }


}