import { Controller } from  'stimulus'

export default class extends Controller {
    static targets = ['submit', 'input']
    connect(){
        for (const input of this.inputTargets) {
            input.disabled = !input.closest("div").querySelector(".update-field-toggle").checked
        }
        for (const input of this.inputTargets) {
            if (!input.disabled) {
                this.submitTarget.disabled = false
                return
            }
            this.submitTarget.disabled = true
        }
    }

    handleLock(e){
        const dataField  = e.target.closest("div").lastElementChild
        if (e.target.checked){
            dataField.disabled = false
            this.submitTarget.disabled = false
        } else {
            dataField.disabled = true
            for(const input of this.inputTargets){
                if (!input.disabled) return
            }
            this.submitTarget.disabled = true
        }
    }


}