import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["items", "spinner"]

    connect() {
        if (!this.hasSpinnerTarget) return

        this.page = 1
        this.loading = false
        this.hasMore = true

        const observer = new IntersectionObserver(
            entries => this.handleIntersection(entries),
            { rootMargin: '100px' }
        )

        observer.observe(this.spinnerTarget)
        this.spinnerTarget.classList.remove('hidden')
    }

    handleIntersection(entries) {
        const [entry] = entries
        if (entry?.isIntersecting && !this.loading && this.hasMore) {
            this.loadMore()
        }
    }

    async loadMore() {
        if (this.loading || !this.hasMore) return
        this.loading = true

        try {
            const baseUrl = this.element.dataset.infiniteScrollUrl
            const url = new URL(baseUrl, window.location.origin)
            url.searchParams.append('page', this.page + 1)

            const { content, has_more } = await fetch(url, {
                headers: {
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                }
            }).then(r => r.json())

            if (content) {
                this.itemsTarget.insertAdjacentHTML('beforeend', content)
                this.page++
                this.hasMore = has_more
            } else {
                this.hasMore = false
            }
        } catch (error) {
            console.error(error)
            this.hasMore = false
        } finally {
            this.loading = false
            this.spinnerTarget.classList.toggle('hidden', !this.hasMore)
        }
    }
}