import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['property', 'responseValuesContainer']
  static values = { url: String, paramKey: String }

  connect () {
    this.propertyFilterNum = this.element.dataset.propertyFilterNum
    $(this.propertyTarget).select2()
    $(this.propertyTarget).on('select2:select', function() {
      let event = new Event('change', { bubbles: true })
      this.dispatchEvent(event)
    })
    let connectedEvent = new Event("NOTIS:connected", { bubbles: true })
    this.element.dispatchEvent(connectedEvent)
  }

  onPropertySelect () {
    const propIdField = $(this.propertyTarget)
    $.ajax(this.urlValue, {
      data: {
        property_id: propIdField.val(),
        property_filter_num: this.propertyFilterNum,
        param_key: this.paramKeyValue
      },
    }).done(data => {
      this.responseValuesContainerTarget.innerHTML = data
      // Check to see if values need to be filled for a canned report
      if (propIdField.data().report) {
        $(this.responseValuesContainerTarget).
          find('.property_response_values_match').
          val(propIdField.data().report.match).
          trigger('change')
        $(this.responseValuesContainerTarget).
          find('.property_response_values').
          val(propIdField.data().report.val).
          trigger('change')
      }
    })
  }

}
