import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ["personnelType"]

  onProtocolPersonnelScopeChange(event) {
    if (this.personnelTypeTargets.length) {
      const result = confirm(`Changing this will remove all ${this.personnelTypeTargets.length} Personnel Types listed below, continue?`);
      if (!result) {
        event.preventDefault()
        return false
      }
    }
    $(this.element).find(".loading").show()
    $(event.target).closest('form').submit()
    $(event.target).closest('form').find("input").each(function () {
      $(this).attr("disabled", true)
    })
  }
}
