import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['propertyContainer'];
    static values = { path: String };

    connect() {
        const self = this;
        this.propertyContainerTargets.forEach((container) => {
            $(container).find('input, select, textarea').each(function () {
                $(this).change(() => {
                    const automatedName = this.name.split('[');
                    automatedName.splice(-1, 1, 'automated]');
                    $(`[name="${automatedName.join('[')}"]`).val('false');
                    self.getVisibilities();
                });
            });
        });
    }

    async getVisibilities() {
        const $form = $(this.element);

        const formData = $form.find(':input:not([name="_method"])').serialize();

        const response = await $.ajax({
          url: this.pathValue,
          type: 'POST',
          dataType: 'json',
          data: formData,
        });

        response.forEach((row) => {
            const $container = $(`#property-container-${row.property_id}`);
            if ($container.is(':visible') && !row.visible) {
                $container.find('input, select, textarea').not('[type="hidden"],[type="checkbox"],[type="radio"]').val('');
                $container.slideUp('fast');
            } else if ($container.is(':hidden') && row.visible) {
                $container.slideDown('fast');
            }
            if (row.visible && row.value) {
                $container.find('input, select, textarea').not('[type="hidden"],[type="checkbox"],[type="radio"]').val(row.value);
                $container.find('[name$="automated]"]').val('true');
                $container.effect('highlight', {}, 1000);
            }
        });
    }
}